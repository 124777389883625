import { createRouter, createWebHistory } from "vue-router";
import { useAuthStore } from "@/stores/authStore";
import { Policy } from "@/enums/Policy";
import { differenceInMinutes } from "date-fns";
import AccessDeniedView from "@/views/error/AccessDeniedView.vue";
import SignedOutView from "@/views/base/SignedOutView.vue";
import LandingPageView from "@/views/base/LandingPage.vue";
import reportView from "@/views/base/ReportView.vue";
import publishReport from "@/views/base/PublishReportView.vue";
import MaintainNotificationView from "@/views/notifications/MaintainNotificationView.vue";
import AddNotificationView from "@/views/notifications/AddNotificationView.vue";
import authService from "@/services/application/authService";
import applicationService from "@/services/application/applicationService";
import AboutView from "@/views/base/AboutView.vue";
const AUTH_CHECK_THRESHOLD = 10;
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: [
        {
            path: "/",
            name: "landingPage",
            component: LandingPageView,
            meta: {
                requiresAuth: false,
                showReport: false
            },
        },
        {
            path: "/kpis",
            name: "KPIs",
            component: reportView,
            meta: {
                title: "KPIs",
                requiresAuth: true,
                requiredPolicy: [Policy.BASIC_ACCESS],
                showReport: true,
                pages: [
                    { displayName: "Home", showWeekFilter: false, showProductFilter: false },
                    { displayName: "[Store] Home", showWeekFilter: false, showProductFilter: false },
                    { displayName: "Sales", showWeekFilter: false, showProductFilter: false },
                    { displayName: "[Store] Sales", showWeekFilter: false, showProductFilter: false },
                    { displayName: "Schedule", showWeekFilter: false, showProductFilter: false },
                    { displayName: "[Store] Schedule", showWeekFilter: false, showProductFilter: false },
                    { displayName: "Stockloss", showWeekFilter: false, showProductFilter: false },
                    { displayName: "[Store] Stockloss", showWeekFilter: false, showProductFilter: false },
                    { displayName: "Hours Utilisation", showWeekFilter: false, showProductFilter: false },
                    { displayName: "[Store] Hours Utilisation", showWeekFilter: false, showProductFilter: false },
                    { displayName: "Labour Turnover", showWeekFilter: false, showProductFilter: false },
                    { displayName: "[Store] Labour Turnover", showWeekFilter: false, showProductFilter: false },
                    { displayName: "Customer", showWeekFilter: false, showProductFilter: false },
                    { displayName: "[Store] Customer", showWeekFilter: false, showProductFilter: false },
                    { displayName: "Customer YTD", showWeekFilter: false, showProductFilter: false },
                    { displayName: "[Store] Customer YTD", showWeekFilter: false, showProductFilter: false },
                    { displayName: "Controllable Costs", showWeekFilter: false, showProductFilter: false },
                    { displayName: "[Store] Controllable Costs", showWeekFilter: false, showProductFilter: false },
                    { displayName: "Audit", showWeekFilter: false, showProductFilter: false },
                    { displayName: "[Store] Audit", showWeekFilter: false, showProductFilter: false }
                ]
            },
        },
        {
            path: "/home",
            name: "Home",
            component: reportView,
            meta: {
                title: "Home",
                requiresAuth: true,
                requiredPolicy: [Policy.BASIC_ACCESS],
                showReport: false
            },
        },
        {
            path: "/about",
            name: "about",
            component: () => import("../views/base/AboutView.vue"),
            meta: {
                title: "About",
                requiredPolicy: [Policy.BASIC_ACCESS],
                showReport: false
            },
        },
        {
            path: "/apps",
            name: "apps",
            component: () => import("../views/base/AboutView.vue"),
            meta: {
                title: "Applications",
                parentRouteName: "home",
                showReport: false
            }
        },
        {
            path: "/accessDenied",
            name: "accessDenied",
            component: AccessDeniedView,
            meta: {
                requiresAuth: true,
                showReport: false,
                title: "Access Denied"
            },
        },
        {
            path: "/signedout",
            name: "SignedOut",
            component: SignedOutView,
            meta: {
                requiresAuth: false,
                showReport: false,
                title: "Signed Out"
            },
        },
        {
            path: "/Sales",
            name: "SalesRoute",
            component: reportView,
            meta: {
                title: "Sales And Performance",
                requiresAuth: true,
                parentRouteName: "home",
                requiredPolicy: [Policy.BASIC_ACCESS],
                showReport: true,
                pages: [
                    { displayName: "Dashboard" },
                    { displayName: "Hourly Sales", showWeekFilter: false, showDateFilter: true },
                    { displayName: "[Store] Hourly Sales", showWeekFilter: false, showDateFilter: true },
                    { displayName: "Transactions", showProductFilter: false },
                    { displayName: "[Store] Transactions", showProductFilter: false }
                ]
            },
        },
        {
            path: "/Inventory",
            name: "InventoryRoute",
            component: reportView,
            meta: {
                title: "Inventory Performance",
                requiresAuth: true,
                parentRouteName: "home",
                requiredPolicy: [Policy.BASIC_ACCESS],
                showReport: true,
                pages: [
                    { displayName: "Dashboard" },
                    { displayName: "BSM Compliance", showProductFilter: false },
                    { displayName: "POP Compliance", showProductFilter: false }
                ]
            }
        },
        {
            path: "/Costs",
            name: "CostsRoute",
            component: reportView,
            meta: {
                requiresAuth: true,
                requiredPolicy: [Policy.BASIC_ACCESS],
                parentRouteName: "home",
                showReport: true,
                title: "Costs",
                pages: [
                    { displayName: "Dashboard" },
                    { displayName: "Absence Rate", showProductFilter: false },
                    { displayName: "[Store] Absence Rate", showProductFilter: false },
                    { displayName: "Hours Util", showProductFilter: false },
                    { displayName: "[Store] Hours Util", showProductFilter: false },
                    { displayName: "Labour Turnover", showProductFilter: false },
                    { displayName: "[Store] Labour Turnover", showProductFilter: false },
                    { displayName: "Cash Adj", showProductFilter: false },
                    { displayName: "[Store] Cash Adj", showProductFilter: false },
                ]
            }
        },
        {
            path: "/People",
            name: "PeopleRoute",
            component: reportView,
            meta: {
                title: "People",
                requiresAuth: true,
                parentRouteName: "home",
                requiredPolicy: [Policy.BASIC_ACCESS],
                showReport: true,
                pages: [
                    { displayName: "Dashboard", showProductFilter: false },
                    { displayName: "[Store] Dashboard", showProductFilter: false },
                    { displayName: "Absence", showProductFilter: false },
                    { displayName: "[Store] Absence", showProductFilter: false },
                    { displayName: "STS", showProductFilter: false },
                    { displayName: "[Store] STS", showProductFilter: false },
                    { displayName: "LTS", showProductFilter: false },
                    { displayName: "[Store] LTS", showProductFilter: false },
                    { displayName: "AWOL", showProductFilter: false },
                    { displayName: "[Store] AWOL", showProductFilter: false },
                    { displayName: "LTO", showProductFilter: false, showWeekFilter: false },
                    { displayName: "[Store] LTO", showProductFilter: false, showWeekFilter: false },
                    { displayName: "Working", showProductFilter: false },
                    { displayName: "Working Data", showProductFilter: false },
                    { displayName: "[Store] Working", showProductFilter: false },
                    { displayName: "Leavers", showProductFilter: false },
                    { displayName: "Leavers Data", showProductFilter: false },
                    { displayName: "[Store] Leavers", showProductFilter: false },
                    { displayName: "New Starter", showProductFilter: false, showWeekFilter: false },
                    { displayName: "New Starter Data", showProductFilter: false, showWeekFilter: false },
                    { displayName: "[Store] New Starter", showProductFilter: false, showWeekFilter: false },
                    { displayName: "Seasonal", showProductFilter: false },
                    { displayName: "Seasonal Data", showProductFilter: false },
                    { displayName: "[Store] Seasonal", showProductFilter: false },
                    { displayName: "Colleague Absences", showLocationFilter: false, showProductFilter: false, showWeekFilter: false },
                    { displayName: "Colleague Absence Calendar", showLocationFilter: false, showProductFilter: false, showWeekFilter: false },
                    { displayName: "Pro Tem Payments", showProductFilter: false },
                    { displayName: "[Store] Pro Tem Payments", showProductFilter: false },
                    { displayName: "Travel Payments", showProductFilter: false, showWeekFilter: false },
                    { displayName: "[Store] Travel Payments", showProductFilter: false, showWeekFilter: false },
                    { displayName: "Travel Payments Data", showProductFilter: false, showWeekFilter: false },
                    { displayName: "Travel Payment Calendar", showProductFilter: false, showWeekFilter: false },
                    { displayName: "> 48 Hours", showProductFilter: false, showWeekFilter: false },
                    { displayName: "[Store] > 48 Hours", showProductFilter: false, showWeekFilter: false },
                    { displayName: "Future > 48 Hours", showProductFilter: false, showWeekFilter: false },
                    { displayName: "[Store] Future > 48 Hours", showProductFilter: false, showWeekFilter: false },
                    { displayName: "Future > 48 Hours Data", showProductFilter: false, showWeekFilter: false },
                    { displayName: "Timesheet Hours", showProductFilter: false },
                    { displayName: "[Store] Timesheet Hours", showProductFilter: false },
                    { displayName: "Timesheet Data", showProductFilter: false },
                    { displayName: "Standard vs Worked Hours", showProductFilter: false },
                    { displayName: "[Store] Standard vs Worked Hours", showProductFilter: false },
                    { displayName: "Compassionate", showProductFilter: false },
                    { displayName: "Compassionate Data", showProductFilter: false },
                    { displayName: "[Store] Compassionate", showProductFilter: false },
                    { displayName: "Suspensions", showProductFilter: false, showWeekFilter: false },
                    { displayName: "[Store] Suspensions", showProductFilter: false, showWeekFilter: false },
                    { displayName: "Investigations", showProductFilter: false, showWeekFilter: false },
                    { displayName: "[Store] Investigations", showProductFilter: false, showWeekFilter: false },
                    { displayName: "Disciplinaries", showProductFilter: false, showWeekFilter: false },
                    { displayName: "[Store] Disciplinaries", showProductFilter: false, showWeekFilter: false },
                    { displayName: "Disciplinaries Data", showProductFilter: false, showWeekFilter: false },
                    { displayName: "Investigations", showProductFilter: false, showWeekFilter: false },
                    { displayName: "Investigations Data", showProductFilter: false, showWeekFilter: false },
                    { displayName: "[Store] Investigations", showProductFilter: false, showWeekFilter: false },
                ]
            },
        },
        {
            path: "/Fleet",
            name: "FleetRoute",
            component: reportView,
            meta: {
                requiresAuth: true,
                requiredPolicy: [Policy.BASIC_ACCESS],
                parentRouteName: "home",
                showReport: true,
                title: "Fleet",
                pages: [
                    { displayName: "Dashboard", showProductFilter: false, showWeekFilter: false },
                    { displayName: "[Store] Dashboard", showProductFilter: false, showWeekFilter: false }
                ]
            }
        },
        {
            path: "/CustomReport",
            name: "CustomReport",
            component: reportView,
            meta: {
                requiresAuth: true,
                requiredPolicy: [Policy.BASIC_ACCESS],
                parentRouteName: "home",
                showReport: true,
                title: "Custom Report"
            }
        },
        {
            path: "/publish",
            name: "publish",
            component: publishReport,
            meta: {
                title: "Publish Report",
                requiresAuth: true,
                requiredPolicy: [Policy.SYS_ADMIN],
                showReport: false
            },
        },
        {
            path: "/admin",
            name: "AdminRoute",
            component: AboutView,
            meta: {
                title: "Admin Menu",
                requiresAuth: true,
                requiredPolicy: [Policy.SYS_ADMIN],
                showReport: false
            }
        },
        {
            path: "/admin/notification",
            name: "Maintain Notifications",
            component: MaintainNotificationView,
            meta: {
                title: "Maintain Notifications",
                requiresAuth: true,
                requiredPolicy: [Policy.SYS_ADMIN],
                showReport: false
            }
        },
        {
            path: "/admin/notification/add",
            name: "Add Notification",
            component: AddNotificationView,
            meta: {
                title: "Add Notification",
                requiresAuth: true,
                requiredPolicy: [Policy.SYS_ADMIN],
                showReport: false
            }
        }
    ],
});
router.beforeEach(async (targetRoute) => {
    document.title = `Viewpoint | ${targetRoute.meta.title ? targetRoute.meta.title : ""}`;
    const authStore = useAuthStore();
    const authSvc = new authService();
    const requiresAuth = targetRoute.matched.some((route) => route.meta.requiresAuth);
    function needsAuthCheck() {
        const authCheck = differenceInMinutes(new Date(), authStore.lastAuthenticated) >
            AUTH_CHECK_THRESHOLD;
        return authCheck;
    }
    function authenticated() {
        return authStore.user?.isAuthenticated ?? false;
    }
    if (requiresAuth ?? true) {
        if (!authenticated() || needsAuthCheck()) {
            await authSvc.getUser();
            if (!authenticated()) {
                authSvc.login(targetRoute.fullPath);
                return false;
            }
        }
        if (!authStore.user.meetsPolicies((targetRoute.meta.requiredPolicy ?? []))) {
            {
                router.push("AccessDenied");
            }
        }
    }
});
router.afterEach((fromRoute, targetRoute, failure) => {
    const appService = new applicationService();
    appService.updateBreadcrumbs(router);
});
export default router;
